import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./card.module.css";

const Card = ({ selectedNavOptionLabel, cardData }) => {
    const navigate = useNavigate();

    if (cardData.cat === selectedNavOptionLabel) {
        return(
            <div key = { cardData.slug } className = { ["card", "mb-3", styles.cardContainer].join(" ") } onClick = {() =>{ navigate(`projects/${cardData.slug}`) }}>
                <img className = "card-img-top" src = { `https://${cardData.img}` } alt = "a portfolio card"></img>
                <div className = "card-body">
                    <h5 className = "card-title">{ cardData.title }</h5>
                    <p className = "card-text">{ cardData.desc }</p>
                </div>
            </div>        
        )
    }
}

export default Card
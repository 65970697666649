import * as React from "react";
import styles from "./nav.module.css";

const NavItemDivWrapper = (label, index, handlers, selectedOption) => {    
    if(index === selectedOption){
        return(
            <React.Fragment key = { index }>
                <div className = { styles.selected } onClick = { (e) => handlers.click(e) } data-nav-key = { index } role = "menuitem" tabIndex = "-1">
                    { label }
                </div>
            </React.Fragment>                
        )
    }
    else{
        return(
            <React.Fragment key = { index }>
                <div onClick = { (e) => handlers.click(e) } data-nav-key = { index } role = "menuitem" tabIndex = "-1">
                    { label }
                </div>
            </React.Fragment>                
        )        
    }
}

export default NavItemDivWrapper
import React from "react";
import Card from "./card";
import styles from "./cards.module.css";

const Cards = ({ selectedNavOptionLabel, projects }) => {
    return(
        <div className = { styles.cardsContainer }>
            {
                Object.keys(projects).map(key => <Card selectedNavOptionLabel = { selectedNavOptionLabel } cardData = { projects[key] }></Card>)
            }
        </div>
    )
}

export default Cards
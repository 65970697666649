export const ObjectUtils = {
    objectIndex: (obj, checkKey, checkValue) => {
      /**
       * The `objectIndex` method returns the index of a supplied key or value within an object.
       * 
       * @param {object} `obj`: The object to iterate over.
       * @param {Any} `checkKey`: The key to search for; `null` if using a value.
       * @param {Any} `checkValue`: The value to search for; `null` if using a value.
       * @return {int} `index`: The index of the supplied key or value.
       * @raise {Exception}
       */
      
      let foundIndex;
          
      try {
          if ((null !== checkKey) && (null === checkValue)) {
              Object.keys(obj).forEach((key, index) => {
                  if (key === checkKey) {
                      foundIndex = index;
                  }
              })
          } else if ((null !== checkValue) && (null === checkKey)) {
              Object.values(obj).forEach((val, index) => {
                  if (val === checkValue) {
                      foundIndex = index;
                  }
              })
          }
          return foundIndex;
      } catch (e) {
          console.log("Error in module Utils and method objectIndex: ", e);
      }    
  }
}

export const StringUtils = {
    stripOutNonAlphaNumeric: (strings) => {
      /**
       * The `stripOutNonAlphaNumeric` method accepts an array of strings and strips out any non-alphanumeric
       * characters. The method returns a cleansed array. 
       *
       * @param {array} `strings`: An array of strings.
       * @return {array} _: A cleansed array of strings.
       * @raise {Exception}
       */

      try{
        let cleanStrings = [];
        strings.forEach((str, i) => {
          let clean = "";
          for (let j = 0; j < str.length; j++) {
            let code = str.charCodeAt(j);
            if ((code > 31 && code < 259)) {
                clean += str[j];
            }
          }
          if ("" !== clean) {
            cleanStrings.push(clean);
          }
        })
        return cleanStrings;
      } catch (e) {
        console.log("Error in module Utils and method stripOutNonAlphaNumeric: ", e);
      }
  },
  
  convertURLToImageFilename: (url) => {
    /**
     * The `convertURLToImageFilename` takes a URL string parameter and returns an image filename using the
     * filename saving scheme from the 'website_image_generator.py' script.
     * 
     * @param {string} `url`: The URL to convert.
     * @return {string} `filename`: The resulting filename after converting the URL string.
     * @raise {Exception}
     */
  
    const REGEX = {
      URL: /http[s]*:[a-zA-Z0-9_.+-/#~]+/,
      SPECIAL: /[./#?~_]/g,
      MULTI_DASH: /[-]{2,}/g,
    }
  
    try {
      let filename = url;
      
      // Strip protocol
      if (filename.indexOf("http://") > -1) {
        filename = filename.replace("http://", "");
      } else if (filename.indexOf("https://") > -1) {
        filename = filename.replace("https://", "");
      }
  
      // Replace any special characters with a single dash
      filename = filename.replaceAll(REGEX.SPECIAL, "-");
  
      // Remove any trailing dashes
      while ("-" === filename[filename.length - 1]) {
        filename = filename.substring(0, filename.length - 1);
      }
  
      // Remove any multi-dash sequences
      filename = filename.replaceAll(REGEX.MULTI_DASH, "-");
  
      // Finally, change all letters to lower case
      filename = filename.toLowerCase();
  
      return filename;
    } catch (e) {
      console.log("Error in module Utils and method convertURLToImageFilename: ", e);
    }
  },

  capitalizeSentences: (str) => {
    /**
     * The `capitalizeSentences` method accepts a string and capitalizes the first letter of each
     * sentence using a "cheap" tokenizer approach that splits strings on punctuation.
     * 
     * @param {string} `str`
     * @return {string} `capStr`: The capitalized string.
     * @raise {Exception} `e`
     */
    try {
      let capStr = "";
      let sentences = str.split(/[?.!:]{1}/);
      let punctuation = str.replace(/[^?.!:]/g, "").split("");
      
      sentences = sentences.filter(s => s);

      if (1 === sentences.length) {
        capStr = str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        let i;
        let sentence;
        for (i = 0; i < sentences.length; i++) {
          sentence = sentences[i].trim();
          capStr = capStr + sentence.charAt(0).toUpperCase() + sentence.slice(1) + punctuation[i] + " ";
        }
        capStr = capStr.trimEnd();
      }
      return capStr
    } catch (e) {
      return e
    }
  },

  capitalizeWords: (str) => {
    /**
     * The `capitalizeWords` method accepts a string and capitalizes the first letter of each
     * word by splitting the string on a single whitespace character.
     * 
     * @param {string} `str`
     * @return {string} `capWords`: The capitalized string.
     * @raise {Exception} `e`
     */
    try {
      let capWords = "";
      let w;
      let words = str.split(" ");
      for (w in words) {
        capWords = capWords + words[w].charAt(0).toUpperCase() + words[w].slice(1) + " ";
      }
      capWords = capWords.trimEnd();
      return capWords
    } catch (e) {
      return e
    }
  },

  cleanCRNL: (dirtyString) => {
    /**
     * The `cleanCRNL` method accepts a `dirtyString` string and strips it of carriage return and newline characters.
     * 
     * @param {string} dirtyString
     * @return {string} _cleanString
     * @raise none
     */
    try {
      let _cleanString = dirtyString.replace(/\r|\n/g, "");
      return _cleanString
    } catch {
      return ""
    }
  },

  validateEmail: (str) => {
    /**
     * The `validateEmail` method accepts an email string and validates it.
     * 
     * @param {string} str
     * @return {boolean}
     * @raise none
     */
    // Regular expression for email validation that supports Unicode characters
    const REGEX_EMAIL = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    try {
      if (REGEX_EMAIL.test(str)) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }
  },

  validatePassword: (str) => {
    /**
     * The `validatePassword` method accepts a password string and validates it.
     * 
     * @param {string} str
     * @return {boolean}
     * @raise none
     */
    const REGEX_PW = /^(?=.*[A-Z])(?=.*[0-9]).{8,16}$/

    try {
      if (REGEX_PW.test(str)) {
        return true
      } else {
        return false
      }
    } catch {
      return false
    }    
  },

  checkPasswords: (password, confirmPassword) => {
    let checks = { goodPassword: false, goodMatch: false };

    if (password === confirmPassword) {
        checks.goodMatch = true;
        if (StringUtils.validatePassword(password)) {
            checks.goodPassword = true;
        }
    }
    return checks
  }
}

export const WebUtils = {
  request: async (url, data) => {
    let response = await fetch(url, {
          method: "POST",
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
          body: "data="+data
        }
    );
    if (response.ok) {
        let text = await response.text();
        return text;
    } else {
      return null;
    }
  }
}

export const DateUtils = {
  monthNames: {
    1: { "abbr": "Jan", "full": "January" },
    2: { "abbr": "Feb", "full": "February" },
    3: { "abbr": "Mar", "full": "March" },
    4: { "abbr": "Apr", "full": "April" },
    5: { "abbr": "May", "full": "May" },
    6: { "abbr": "Jun", "full": "June" },
    7: { "abbr": "Jul", "full": "July" },
    8: { "abbr": "Aug", "full": "August" },
    9: { "abbr": "Sep", "full": "September" },
    10: { "abbr": "Oct", "full": "October" },
    11: { "abbr": "Nov", "full": "November" },
    12: { "abbr": "Dec", "full": "December" }
  },

  daysNames: {
    0: { "abbr": "Sun", "full": "Sunday" },
    1: { "abbr": "Mon", "full": "Monday" },
    2: { "abbr": "Tue", "full": "Tuesday" },
    3: { "abbr": "Wed", "full": "Wednesday" },
    4: { "abbr": "Thur", "full": "Thursday" },
    5: { "abbr": "Fri", "full": "Friday" },
    6: { "abbr": "Sat", "full": "Saturday" }
  },

  convertDateTimeTicksToStandardDate: (timestamp) => {
      /**
       * This method accepts a timestamp and converts it to a Javascript Date object. The timestamp is expressed as
       * C# DateTime ticks which represents dates and times with values ranging from 12:00:00 midnight,
       * January 1, 0001 Anno Domini (Common Era) through 11:59:59 P.M., December 31, 9999 A.D. (C.E.).
       * There are 10,000 milliseconds in a tick.
       * 
       * The timestamp can be converted to POSIX time (number of milliseconds since the UNIX Epoch) by subtracting
       * the number of ticks to the UNIX Epoch from the timestamp. The resulting number is then divided by 10,000
       * to produce the POSIX time value.
       *
       * Note that the Unix Epoch in ticks is 621355968000000000. This value has to be expressed as a BigInt
       * primitive in Node.js, e.g. 621355968000000000n or BigInt(621355968000000000).
       *  
       * @param {BigInt} `timestamp`: The timestamp expressed as a BigInt integer.
       * @return {object} `standardDate`: The timestamp as a Javascript Date object.
       * @raise {Exception} `e`
       */

      const TO_UNIX_EPOCH_IN_MILLI = 62135596800000; // Number of milliseconds from January 1, 0001 to Unix Epoch in milliseconds.

      try {
        timestamp = timestamp / 10000;
        let POSIXTime = Math.round(timestamp - TO_UNIX_EPOCH_IN_MILLI);
        let standardDate = new Date(POSIXTime);
        return standardDate
      } catch (e) {
        return e
      }
    },

    convertTimestampToStandardDate: (timestamp) => {
      /**
       * The `convertTimestampToStandardDate` method converts a timestamp to a standard JavaScript
       * date object.
       * 
       * @param {int} `timestamp`
       * @return {object} `standardDate`
       * @raise {Exception} `e`
       */
      try {
        let standardDate = new Date(timestamp * 1000);
        return standardDate
      } catch (e) {
        return e
      }
    },

    stripHour: (dateString) => {
      /**
       * The `stripHour` method is specific to the Inveyo report string format. It stripts the hour
       * value from a supplied `dateString` and returns the remaining string. For example, if the 
       * report string is `2023_05_23_12`, the method returns `2023_05_23`.
       * 
       * @param {string} `dateString`: The date string with an hour value for stripping.
       * @return _: The stripped string.
       * @raise None
       */
      if (dateString.length > 10) {
        return dateString.slice(0, 10)
      }
    },

    convertDate: (date, format) => {
        /**
         * The `convertDate` method accepts a date expression as a string and converts it to a specified format.
         * 
         * @param {string} `date`: The date expression to convert to a specified format.
         * @param {string} `format`: The format value to be used with conversion (dash, slash). 
         * @return {string} _: The converted date expression.
         * @raise {Exception}
         */
        const _date = date;

        const REGEX = {
          "DATE_YEAR_FIRST_DASH": /^[\d]{4}-[\d]{2}-[\d]{2}$/,
          "DATE_DASH": /^[\d]{2}-[\d]{2}-[\d]{4}$/,
          "DATE_SLASH": /^[\d]{2}[/][\d]{2}[/][\d]{4}$/,
          "DATE_UNDERSCORE_NO_HOUR": /^[0-9]{4}_[0-9]{2}_[0-9]{2}$/,
          "DATE_UNDERSCORE_WITH_HOUR": /^[0-9]{4}_[0-9]{2}_[0-9]{2}_[0-9]{1,2}$/
        };

        try {
          let regexKey = null;
          for (regexKey in REGEX) {
              if (REGEX[regexKey].test(_date)) {
                  
                  let _year = null;
                  let _month = null;
                  let _day = null;
                  // let _hour = null;

                  switch (regexKey) {
                      case ("DATE_YEAR_FIRST_DASH"):
                        _year = _date.slice(0, 4);
                        _month = _date.slice(5, 7);
                        _day = _date.slice(8, 10);
                        break;
                      case ("DATE_SLASH"):
                        _year = _date.slice(6, 10);
                        _day = _date.slice(3, 5);
                        _month = _date.slice(0, 2);
                        break;
                      case ("DATE_UNDERSCORE_NO_HOUR"):
                        _year = _date.slice(0, 4);
                        _month = _date.slice(5, 7);
                        _day = _date.slice(8, 10);
                        break;
                      case ("DATE_UNDERSCORE_WITH_HOUR"):
                        _year = _date.slice(0, 4);
                        _month = _date.slice(5, 7);
                        _day = _date.slice(8, 10);
                        // _hour = _date.slice(11, 13);
                        break;
                      default:
                        _year = _date.slice(0, 4);
                        _month = _date.slice(5, 7);
                        _day = _date.slice(8, 10);                   
                  }
                  
                  switch (format) {
                      case ("DATE_SLASH"):
                        return _month + "/" + _day + "/" + _year;
                      case ("DATE_FULL"):
                        // Construct date object
                        let _dateObj = DateUtils.newDateObject(_month + "-" + _day + "-" + _year);

                        // Get the index of the _day name
                        let _dayNameIndex = _dateObj.getDay();

                        // Fetch the full day name
                        let _dayNameFull = DateUtils.daysNames[_dayNameIndex]["full"];

                        // Fetch the full month name
                        let _monthNameFull = DateUtils.monthNames[parseInt(_month.replace(/^[0]{1}/, ""))]["full"];

                        // Assemble full date.
                        let _fullDate = _dayNameFull + ", " + _monthNameFull + " " + _day + ", " + _year

                        return _fullDate;
                      default:
                        return _month + "/" + _day + "/" + _year;
                  }            
              }
          }
        } catch (e) {
          console.log("Error in module Utils and method convertDate: ", e);
        }
    },

    subtractDaysFromDateObject: (dateObj, days) => {
      /**
       * The `subtractDaysFromDateObject` function accepts a date object and subtracts a specified number
       * of days from it. The new date is returned as a date object.
       * 
       * @param {object} `dateObj`: The original date object.
       * @param {int} `days`: The number of days to subtract from the original date.
       * @return {object} _: The new date as a date object.
       * @raise none
       */

      return dateObj.setDate(dateObj.getDate() - days);
    },

    getTodaysDate: () => {
      /**
       * The `getTodaysDate` function returns today's date.
       * 
       * @param none
       * @return {object} Today's date as a date object.
       * @raise none
       */

      let today = new Date();
      return today;
    },

    newDateObject: (dateString) => {
      /**
       * The `newDateObject` method accepts a `dateString` parameter and converts it to a `Date` object.
       * 
       * @param {string} `dateString`
       * @return {object} `dateObject`
       * @raise none
       */

      try {
        let dateObject = new Date(dateString);
        return dateObject
      } catch {
        return null;
      }
    },

    getDaysDiffFromNow: (date) => {
      /**
       * The `getDaysDiffFromNow` method accepts a date string, converts it to a date, and determines
       * the difference between the supplied date and today's date in days.
       * 
       * @param {string} `date`
       * @return {int} `diffDays`
       * @raise none
       */
      try {
        let _date = new Date(date);
        let _today = new Date();

        let _daysDiff = parseInt(((_today - _date) / (1000 * 60 * 60 * 24)));
        return _daysDiff
      } catch {
        return 0
      }
    }
}

export const MathUtils = {
  addThousandsSeparator: (numberString, separator) => {
    /**
     * The `addThousandsSeparator` utility function adds a specified thousands separator to a numerical
     * value supplied as a string.
     * 
     * @param {string} `numberString`: The numerical value to be formatted.
     * @param {string} `separator`: The separator be used (e.g. ",", ".").
     * @return {string} `separatedNumberString`: The formatted numerical value.
     * @raise none
     */

    // Check if numberString is a string; if not, convert it
    if (typeof numberString !== "string") {
      numberString = numberString.toString();
    }

    // Set the starting index 3 positions from the end of the numerical string
    let i = numberString.length - 3;
    let digitsAdded = 0;
    let separatedNumberString = "";
    let slice = "";

    for (i; i > 0; i = i - 3) {
      slice = numberString.slice(i, i + 3);
      separatedNumberString = separator + slice + separatedNumberString;
      digitsAdded += 3;
    }

    // Add any "leftover" digits
    slice = numberString.slice(0, numberString.length - digitsAdded);
    separatedNumberString = slice + separatedNumberString;

    // Return the separated numerical string
    return separatedNumberString;
  }, 
  
  numberConvertToString: (value, formatList, symbol, separator, decimals) => {
    /**
     * The `numberConvertToString` method formats a supplied number using supplied format and symbol
     * parameters and returns a string.
     *
     * @param {int, float} value: The number to convert to a string. 
     * @param {array} formatList: An array of format parameters: ADD_SYMBOL, SEPARATOR, TBMK, DECIMALS.
     * @param {string} symbol: An optional currency symbol.
     * @param {string} separator: An optional thousands symbol.
     * @param {int} decimals: An optional number of fixed decimals values for the numerical string.
     * @return {string} numberString: The final string.
     */        

    let numberSuffix = "";
    let numberString = "";

    // Check if value is NaN; set to 0 if true
    if (isNaN(value)) {
      value = 0;
    }

    // Add currency symbol if specified
    if (formatList.indexOf("ADD_SYMBOL") > -1) {
      if (("" !== symbol) && (null !== symbol) && (undefined !== symbol)) {
        numberString = numberString + symbol;
      }
    }

    // Generate magnitude suffix if specified
    if (formatList.indexOf("TBMK") > -1) {
      // Cast value to int
      value = parseInt(value);

      // Determine magnitude suffix
      if ((value > 1000) && (value < 1000000)) {
          value = value / 1000;
          numberSuffix = "K";
      } else if ((value > 1000000) && (value < 1000000000)) {
          value = value / 1000000;
          numberSuffix = "M";
      } else if ((value > 1000000000) && (value < 1000000000000)) {
          value = value / 1000000000;
          numberSuffix = "B";                
      } else if (value > 1000000000000) {
          value = value / 1000000000000;
          numberSuffix = "T";                
      } else {
          // pass
      }
    }

    // Add separator if specified
    if (formatList.indexOf("SEPARATOR") > -1) {
      value = MathUtils.addThousandsSeparator(value, separator); // addThousandsSeparator function will convert value to string.
    }

    // Add decimals if specified
    if (formatList.indexOf("DECIMALS") > -1) {
      value = parseFloat(value).toFixed(decimals);
      if (isNaN(value)) {
        value = parseFloat(0).toFixed(decimals);
      }
    }

    // Add percent suffix if specified
    if (formatList.indexOf("PERCENT") > -1) {
      value = parseInt(value);
      if (isNaN(value)) {
        value = 0;
      }
      numberSuffix += "%";
    }

    // Construct the final numerical string
    numberString = numberString + value + numberSuffix;

    // Return the final string
    return numberString;
  },

  stringConvertToNumber: (str, cast = "float", symbol = null, separator = null, decimals = 0) => {
    /**
     * The `stringConvertToNumber` method formats a supplied string using supplied format and symbol
     * parameters and returns the string as a number.
     *
     * @param {string} `str`: The string to convert to a number.
     * @param {string} `cast`: The cast type (int, float) for the number.
     * @param {string} `symbol`: An optional currency symbol to remove.
     * @param {string} `separator`: An optional thousands symbol to remove.
     * @param {int} `decimals`: An optional number of fixed decimals values for the number.
     * @return {string} `number`: The string converted to a number.
     * @raise none
     */

    // Create a copy of the supplied string and initialize `_num`
    let _str = str;
    let _num = null;

    // If a symbol was supplied, remove it
    if (null !== symbol) {
      _str = _str.replace(symbol, "");
    }

    // If a separator was supplied, remove it/them
    _str = _str.replaceAll(separator, "");

    // Cast the string to a number
    if ("float" === cast) {
      _num = parseFloat(_str).toFixed(decimals);
      return _num;
    } else {
      _num = parseInt(_str);
      return _num;
    }
  },

  convertCurrUnitsToFormattedCurr: (currUnits, curr) => {
    /**
     * The `convertCurrUnitsToFormattedCurr` method accepts a `currUnits` value and converts it to a 
     * formatted currency string. For example, `1999` would be converted to `$19.99` if `curr` is `usd`.
     * 
     * @param {int} `currUnits`: The amount of currency units for formatting.
     * @param {str} `curr`: The currency, e.g. `usd`.
     * @return {str} `_formattedCurr`: The formatted currency amount.
     * @raise {Exception} `e`
     */
    try {
      let _formattedCurr = "";
      currUnits = parseInt(currUnits); // Just in case an int was not provided
      if ("usd" === curr) {
        _formattedCurr = "$" + (parseFloat(currUnits/100).toFixed(2)).toString();
      }
      return _formattedCurr;
    } catch (e) {
      return e
    }
  }
}
import * as React from "react";
import NavItemDivWrapper from "./nav-item-div-wrapper.jsx";
import styles from "./nav.module.css";
import strings from "../scripts/strings.js";

const Nav = (props) => {
    return(
        <div className = { styles.navContainer }>
            <div className = { styles.logo }>
                <span></span>
            </div>
            <div className = { styles.logoText }>
                <span></span>
            </div>
            <nav className = { styles.desktopNav }>
                {
                    Object.keys(strings.navLabels).map((key, index) => (NavItemDivWrapper(strings.navLabels[key], index, {"click": props.handlers["navClick"]}, props.selectedOption)))
                }
            </nav>
            <div className = { styles.hamburgerMenu } onClick = { (e) => props.handlers["sideNavOpen"](e) }>
                <div className = { styles.hamburgerMenuIcon } role = "button" tabIndex = "-1">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Nav
import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ObjectUtils, WebUtils } from "../scripts/utils.js";
import Layout from "./layout.jsx";
import Nav from "./nav.jsx";
import SideNav from "./sidenav.jsx";
import Hr from "./hr.jsx";
import Cards from "./cards.jsx";
import DisplayProject from "./display-project.jsx";
import strings from "../scripts/strings.js";
import services from "../scripts/services.js";

const appConfig = {
  "fontSize": 16,
  "sideNavWidth": 20,
  "firstNavLabel": strings.navLabels[Object.keys(strings.navLabels)[0]].toLowerCase()
}

const App = () => {  
  // Set state.
  const [selectedNavOption, setSelectedNavOption] = React.useState(0);
  const [selectedNavOptionLabel, setSelectedNavOptionLabel] = React.useState(appConfig.firstNavLabel);
  const [projects, setProjects] = useState(null);

  const navOptionClickHandler = (e) => {      
    // Fetch the nav option that the user clicked on.
    let clickedNavOption = e.target.innerText.toLowerCase();
    let clickedNavOptionIndex = (ObjectUtils.objectIndex(strings.navLabels, null, clickedNavOption));

    // Update selectedNav.
    setSelectedNavOption(clickedNavOptionIndex);
    setSelectedNavOptionLabel(clickedNavOption);
  }
  
  const sideNavOpenHandler = (e) => {
    const sideNav = document.getElementById("sideNav");
    
    if("click" === e.type){        
        let sideNavPixelWidth = appConfig["fontSize"] * appConfig["sideNavWidth"];
        let windowWidth = window.outerWidth;
        sideNav.style.left = (windowWidth - sideNavPixelWidth) + "px";
    }
  }

  const sideNavCloseHandler = (e) => {
    const sideNav = document.getElementById("sideNav");
    sideNav.style.left = "100%";
  }

  const sideNavOptionClickHandler = (e) => {      
    // Fetch the sideNav option that the user clicked on.
    let clickedSideNavOption = e.target.innerText.toLowerCase();
    let clickedSideNavOptionIndex = (ObjectUtils.objectIndex(strings.navLabels, null, clickedSideNavOption));
    
    // Close the side menu
    sideNavCloseHandler();

    // Update selectedNav.
    setSelectedNavOption(clickedSideNavOptionIndex);
    setSelectedNavOptionLabel(clickedSideNavOption);
  }

  useEffect(() => {
    let _action = {
      "action": "get-projects"
    }
    _action = JSON.stringify(_action);
    WebUtils.request(services.webServices, _action)
    .then((data) => {
      let _projects = JSON.parse(data);
      setProjects(_projects);
    })
  }, [])

  return(
    <Routes>
      <Route path = "/" element = { <Layout></Layout> }>
        <Route index element = {
          <React.Fragment>
            <Nav selectedOption = { selectedNavOption } handlers = {{ "sideNavOpen": sideNavOpenHandler, "navClick": navOptionClickHandler }}></Nav>
            <SideNav selectedOption = { selectedNavOption } handlers = {{ "sideNavClose": sideNavCloseHandler, "sideNavClick": sideNavOptionClickHandler }}></SideNav>
            <Hr></Hr>
            {
              (null !== projects) && <Cards selectedNavOptionLabel = { selectedNavOptionLabel } projects = { projects }></Cards>
            }
          </React.Fragment>
        }>
        </Route>
        <Route path = "projects/:slug" element = { <DisplayProject></DisplayProject> }></Route>
      </Route>
    </Routes>
  )  
}

export default App